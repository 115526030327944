* {
  -webkit-text-size-adjust: none !important;
  -webkit-print-color-adjust: exact !important;
  //font-variant: tabular-nums !important;
  //font-feature-settings: "tnum", "tnum";
}

html {
  -webkit-text-size-adjust: 100% !important;
}

body.is-safari {
  -webkit-font-smoothing: antialiased;
}

.vjs-has-started {
  .play-button-container {
    display: none;
  }
}

.has-played {
  .add-marker {
    display: flex !important;
  }

  .video-cameras {
    display: flex !important;
  }

  .mobile-make-clip-btn {
    display: block;
  }
}

body.is-mobile {
  z-index: 3;

  #root {
    overflow-x: hidden;
  }

  .play-stop-container {
    top: 0;
    height: 100%;
    align-items: center;
  }

  .vjs-big-play-button {
    z-index: 2;
  }

  .vjs-quality-button,
  .vjs-fullscreen-control {
    display: none !important;
  }

  .vjs-break-overlay {
    display: none !important;
  }

  .vjs-tech {
    pointer-events: none !important;
  }

  .mouse-hover-progress {
    //height: 4px;
    bottom: 39px !important;
  }

  .vjs-control-bar {
    .vjs-progress-control {
      order: 7;
      position: absolute;
      top: -1px;
      width: 100%;
      height: max-content;
      padding-bottom: 10px;
      z-index: 2;
      margin: 0;
      cursor: pointer;
      padding-top: unset;

      .vjs-play-progress {
        top: 5px;
      }
    }
  }

  .hidden-bar {
    .vjs-control-bar {
      display: none !important;
    }

    .mobile-make-clip-btn {
      display: none !important;
    }

    .play-stop-container {
      display: none !important;
    }
  }

  @media (min-width: 320px) and (max-width: 499px) {
    //initial
    .vjs-qlist {
      display: none !important;
    }

    .vjs-subs-caps-button {
      display: none !important;
    }

    .vjs-tech {
      max-height: 280px;
      height: 100% !important;
    }

    .inactive-timeline {
      display: none !important;
    }

    .vjs-replay-button {
      width: 100%;
      margin: 0;
      left: unset;
      top: 35%;
    }

    .vjs-menu-settings {
      .vjs-menu-content {
        align-items: center;
        background-color: rgba(255, 255, 255, 0.2) !important;
        max-width: 182px;
        max-height: 30px;
        height: 30px;
        border-radius: 5px;

        .vjs-speed {
          height: 24px;
          font-size: 12px !important;

          margin-right: 0 !important;
        }
      }
    }

    .video-cameras {
      display: none !important;
      top: -55px;
      left: unset;
      right: 10px;
      max-width: 215px;
      height: 43px;

      .hot-key-button {
        display: none;
      }

      .cameras-container {
        display: flex;
        position: relative;
        overflow: hidden !important;
        background: transparent;
        border-radius: 5px;
        margin: unset;
        padding: unset;
        align-items: flex-start;

        .cameras-icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 35px;
          margin: unset;

          img {
            width: 12px;
            height: 12px;
          }
        }

        .numbers-container {
          height: 100%;
          max-width: 175px;
          overflow: scroll;

          .video-cameras-item {
            height: 100%;
            display: flex;
            align-items: center;

            div {
              height: 100%;
            }
          }

          .video-cameras-item.active-number {
          }

          .video-cameras-number {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: unset;
            width: 35px;
            height: 35px !important;
          }

          .thumb-item {
            display: none !important;
          }
        }
      }
    }

    .video-js {
      visibility: visible;
      position: relative;
      height: 180px;
      max-height: 180px;
      padding: 0;
      overflow: unset;

      .vjs-control-bar {
        visibility: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 22px 10px 12px 10px;
        top: 41px;
        height: 64px;
        background: none !important;

        .vjs-cog-menu-button {
          .vjs-menu-speed {
            top: 14px;
            left: -52px;
          }
        }

        .vjs-seek-button {
          width: 18px;
          height: 18px;
        }

        .skip-back {
          margin-left: 165px;
        }

        .skip-forward {
          margin-left: 20px;
        }

        .vjs-progress-control {
          position: absolute;
          top: -20px;

          .vjs-progress-holder {
            height: 2px !important;
            background: rgba(255, 255, 255, 0.25);
          }

          &:hover {
            .vjs-play-progress {
              top: 3px;
            }
          }
        }

        .vjs-current-time,
        .vjs-duration {
          position: absolute;
          bottom: 85px;
          left: 10px;
          font-size: 9px;
        }

        .vjs-current-time {
          margin-left: unset;
          .vjs-current-time-display {
            width: 32px;
          }
        }

        .vjs-duration {
          margin-left: 35px;
          .vjs-duration-display {
            margin-left: 5px;
          }
        }

        .vjs-volume-panel {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: unset;

          &:hover {
            width: 40px;
          }

          .vjs-mute-control {
            height: 100%;
            margin: 0 0 0 17px;
          }

          .vjs-volume-vertical {
            display: none !important;
          }
        }
      }
    }

    .vjs-playing {
      .mob-pause-btn {
        display: block;
      }

      .mob-play-btn {
        display: none;
      }
    }

    .vjs-paused {
      .mob-pause-btn {
        display: none;
      }

      .mob-play-btn {
        display: block;
      }
    }

    .vjs-ended {
      .mob-pause-btn {
        display: none;
      }

      .mob-play-btn {
        display: none;
      }
    }

    .vjs-play-control {
      display: none !important;
    }

    .add-marker-text {
      display: none;
    }

    .vjs-user-active {
      .add-marker {
        left: 10px;
        bottom: 25px;
        width: 30px !important;
        height: 39px !important;
        display: none;
        justify-content: center;
        padding-top: 12px !important;
        z-index: 2 !important;

        .add-marker-text {
          display: none;
        }

        .add-marker-img {
          img {
            width: 12px;
            height: 18px;
            margin-right: unset;
          }
        }
      }
    }

    .message-container {
      max-width: 100%;
      width: 100%;
      padding: 13px 72px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mobile-marker-message {
      .message-container {
        background: rgba(217, 217, 217, 0.2);
        margin: 0 auto;
        width: fit-content;
        top: -67px;
        position: absolute;
        padding: 13px 25px;
        border-radius: 3px;
      }
    }

    //every play
    .has-played {
      .black-background {
        display: block;
      }

      .video-cameras {
        display: flex !important;
      }

      .mobile-make-clip-btn {
        display: block;
      }

      .vjs-control-bar {
        visibility: visible;
      }

      .add-marker {
        display: flex;
      }
    }

    //user inactive
    .user-inactive {
      .vjs-has-started {
        .vjs-control-bar {
          display: none;
        }
      }

      .mob-pause-btn {
        display: none;
      }

      .video-cameras {
        display: none !important;
      }

      .mobile-make-clip-btn {
        display: none;
      }

      &.has-played {
        .mob-play-btn {
          display: none;
        }

        .video-js {
          .vjs-control-bar {
            visibility: visible !important;
            opacity: 1 !important;
            background: #141414;
            display: flex;
            justify-content: center;

            .vjs-seek-button {
              display: none;
            }

            .vjs-volume-panel {
              display: none;
            }

            .vjs-menu-settings {
              display: none !important;
            }
          }
        }

        .add-marker {
          left: 10px;
          bottom: 25px;
          width: 30px !important;
          height: 39px !important;
          display: flex;
          justify-content: center;
          padding-top: 12px !important;
          z-index: 2 !important;

          .add-marker-text {
            display: none;
          }

          .add-marker-img {
            img {
              width: 12px;
              height: 18px;
              margin-right: unset;
            }
          }
        }
      }
    }

    //user active
    .user-active.has-played {
      .vjs-has-started {
        .vjs-control-bar {
          visibility: visible !important;
          opacity: 1 !important;
          display: flex;
          justify-content: center;
        }
      }
    }

    //make a clip
    .hidden-bar {
      .black-background {
        img {
          display: none;
        }
      }

      .confirm-modal-container {
        background: rgba(0, 0, 0, 0.85);
      }

      .make-clip-input-section {
        position: unset;
        height: 0;
        .clip-title-input {
          position: absolute;
          top: -55px;
          left: 10px;
          width: calc(100% - 20px);
          input {
            height: 7px;
          }
        }
      }

      .style-progress-bar-container {
        bottom: -81px;
        div {
          font-size: 12px;
        }
      }

      .rc-slider {
        bottom: -81px;
        background: rgba(255, 255, 255, 0.2);
        padding: unset;
        height: 54px;

        .rc-slider-handle {
          top: 5px;
          background-size: unset;
          div {
            font-size: 9px;
            background: unset;
            height: 11px;
            padding: unset;
            margin-top: -20px;
            margin-left: 5px;
          }
        }

        .rc-slider-handle-2 {
          div {
            margin-left: -5px;
          }
        }
      }

      .make-clip-input-wrapper {
        padding: 0;
        height: 40px;
      }

      .save-clip-button {
        position: absolute;
        padding: 12px 25px 11px 25px;
        font-size: 14px;
        line-height: 17px;
        bottom: -141px;
        left: 11px;
        margin: unset;
      }

      .delete-cancel-buttons-container,
      .back-clip-button {
        position: absolute;
        display: flex;
        bottom: -141px;
        left: 138px;
        margin: unset;
        height: 40px;
        align-items: center;

        .delete-clip-button {
          margin-right: 15px;
        }
      }

      .preview-clip-text {
        display: none;
      }
    }
    .edit-clip-container {
      width: 300px;
      height: 194px;
    }

    .confetti-modal-container {
      width: 300px;
      height: 360px;

      .confetti-modal-title {
        font-size: 12px;
        margin-top: 47px;
        margin-bottom: 33px;
      }

      .gif-container {
        margin-top: 15px;
        max-width: 238px;
        img {
          object-fit: contain;
        }
      }
    }

    .success-bar-container {
      width: 300px;
      height: 360px;

      .success-bar-title {
        font-size: 12px;
        margin-bottom: 49px;
      }

      .download-copy-block {
        width: 210px;

        .copy-block {
          padding: 15px 4px 14px 8px;
          &:active,
          &:hover {
            background-color: #dbedff;
          }

          .link-copied {
            //color: #000;
            justify-content: unset;
            img {
              //filter: unset;
            }
            span {
              margin-left: 60px;
            }
          }

          div {
            margin-top: unset;
            justify-content: space-between;
            width: 100%;
            opacity: 1;

            img {
              width: 16px;
              height: 16px;
              margin-right: unset;
            }

            span {
              font-size: 14px;
              margin-left: 60px;
            }
          }
        }

        .download-block {
          padding: 17px 0 12px 8px;
          &:active,
          &:hover {
            background-color: #dbedff;
          }

          .download-section {
            margin-top: unset;
            opacity: 1;

            img {
              width: 14px;
              height: 16px;
              margin-right: unset;
            }
            span {
              font-size: 14px;
              margin-left: 63px;
            }
          }
        }
      }
    }

    .vjs-control-bar {
      .vjs-progress-control {
        .vjs-tip {
          display: none;
        }
        .vjs-progress-holder {
          .vjs-marker {
            top: -16px;
            width: 9px !important;
            height: 14px !important;

            :hover {
              transform: unset;
            }
          }
          .active-marker {
            top: -54px;
            margin-top: 0 !important;
            width: 12px !important;
            height: 18px !important;
          }
        }
      }
    }

    //add marker
    .add-marker-active {
      .black-background {
        img {
          display: none;
        }
      }
      .add-marker {
        display: none !important;
      }
      .cameras-container {
        display: none;
      }
      .vjs-progress-control {
        pointer-events: none;
      }
      .vjs-marker {
        display: none;
      }
      .active-marker {
        display: block;
        margin-top: unset !important;
        top: -55px;
      }

      .mobile-make-clip-btn,
      .mob-play-btn,
      .mob-pause-btn {
        display: none;
      }

      .marker-desc-container {
        bottom: -112px;
        .marker-desc-content {
          display: flex !important;
          padding: 0 10px;
          width: inherit;
          flex-wrap: wrap;
          grid-gap: 15px;

          .marker-input {
            width: 100%;
          }

          .add-marker-button {
            display: flex;
            order: 1;
            margin: 0 0 0 5px;
          }

          .marker-cancel {
            display: flex;
            order: 0;
            margin-left: auto;
          }
        }
      }

      .cameras-container {
        display: none;
      }
    }

    &.add-marker-active-mobile {
      .main-video-info-wrapper,
      .mobile-exit-marker-btn {
        display: none !important;
      }
    }

    &.edit-marker-mode-mobile {
      .add-marker {
        display: none !important;
      }
    }

    &.mobile-view-marker-data {
      .is-mobile-markers {
        z-index: 3;
        display: block;

        .is-highlight-marker {
          display: none;
        }
      }

      .styled-mobile-container {
        position: relative;
        padding: 0;

        .title-container,
        .labels-wrapper {
          display: none;
        }

        .main-video-info-wrapper {
          margin-top: -33px;
          overflow: scroll;
          height: 38vh;
          max-height: 38vh;
          position: relative;
          .is-mobile-markers {
            .styled-marker-container {
              padding: 4px 10px;
              margin-left: unset;
              display: flex;
              gap: 5px;
              width: 100%;
              align-items: center;

              .styled-marker-text {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-left: 15px;
                color: white;
                width: 100%;
              }
            }
          }
        }
      }

      .video-js {
        .vjs-control-bar {
          .vjs-seek-button {
            display: none;
          }

          .vjs-volume-panel {
            display: none;
          }

          .vjs-menu-settings {
            display: none !important;
          }
        }
      }

      .mobile-make-clip-btn {
        display: none;
      }

      .cameras-container {
        display: none;
      }

      .black-background {
        img {
          display: none;
        }
      }

      .mobile-exit-marker-btn {
        display: block;
      }

      .opacity_55 {
        opacity: 0.55;
      }
    }

    //edit marker
    .edit-marker-mode {
      .vjs-marker:not(.mobile-active) {
        display: none !important;
      }
    }
  }

  @media screen and (min-width: 568px) and (orientation: landscape) {
    & {
      overflow: auto !important;
    }

    .link-copied-for-mobile {
      top: 25px;
    }

    .under-video-section {
      .link-copied {
        margin-left: unset;
      }
    }

    .copy-section {
      margin-right: 20px;
    }

    .styled-wrapper-inner-container {
      flex-direction: column;
    }

    //initial
    .vjs-qlist {
      display: none !important;
    }

    .vjs-subs-caps-button {
      display: none !important;
    }

    .play-stop-container {
      top: 0;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    .vjs-tech {
      height: 100% !important;
    }

    .inactive-timeline {
      display: none !important;
    }

    .vjs-replay-button {
      width: 100%;
      margin: 0;
      left: unset;
      top: 35%;
    }

    .vjs-menu-settings {
      .vjs-menu-content {
        align-items: center;
        background-color: rgba(255, 255, 255, 0.2) !important;
        max-width: 182px;
        max-height: 30px;
        height: 30px;
        border-radius: 6px !important;

        .vjs-speed {
          height: 24px;
          font-size: 10px;

          margin-right: 0 !important;
        }
      }
    }

    .video-cameras {
      display: none !important;
      top: 0;
      left: -46px;
      right: unset;
      width: 46px;

      .hot-key-button {
        display: none;
      }

      .cameras-container {
        background: none;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden !important;
        border-radius: 5px;
        margin: unset;
        padding: unset;
        height: fit-content;
        max-height: 211px;

        .cameras-icon-container {
          width: 46px;
          margin: unset;
          margin-bottom: 10px;

          img {
            margin-left: 12px;
            width: 12px;
            height: 12px;
          }
        }

        .numbers-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 46px;
          overflow: scroll;

          .video-cameras-item {
            height: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 2px;

            div {
              height: 36px;
            }
          }

          .video-cameras-item.active-number {
          }

          .video-cameras-number {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: unset;
            width: 36px;
          }

          .thumb-item {
            display: none !important;
          }
        }
      }
    }

    .video-js {
      visibility: visible;
      position: relative;
      height: 180px;
      max-height: 180px;
      padding: 0;
      overflow: unset;

      .vjs-control-bar {
        visibility: hidden;
        position: relative;
        display: flex;
        align-items: center;
        top: 0;
        justify-content: flex-end;
        height: 40px;
        padding-top: 10px;
        background: none !important;

        .vjs-cog-menu-button {
          height: 30px;
          .vjs-menu-speed {
            top: 10px;
            left: -52px;
          }
          .vjs-menu-settings {
            bottom: 40px;
          }
        }

        .vjs-seek-button {
          width: 18px;
          height: 18px;
        }

        .skip-back {
          margin-left: 184px;
        }

        .skip-forward {
          margin-left: 23px;
        }

        .vjs-progress-control {
          position: absolute;
          top: -14px;

          .vjs-play-progress {
            height: 4px;
          }

          .vjs-progress-holder {
            height: 4px !important;
            background: rgba(255, 255, 255, 0.25);
            position: relative;
            top: 10px;
          }

          &:hover {
            .vjs-play-progress {
              top: 3px;
            }
          }
        }

        .vjs-current-time,
        .vjs-duration {
          font-weight: 600;
          height: 20px;
          position: absolute;
          left: 10px;
          top: 1px;
          font-size: 9px;
          color: #fff;
        }

        .vjs-current-time {
          margin-left: unset;
          left: -2px;
          .vjs-current-time-display {
            width: fit-content;
            min-width: 30px;
          }
        }

        .vjs-duration {
          margin-left: 25px;
          .vjs-duration-display {
            margin-left: 5px;
          }
        }

        .vjs-volume-panel {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-left: unset;
          width: 50px;

          .vjs-mute-control {
            height: 100%;
            width: 25px;
            margin: 0;
          }

          .vjs-volume-vertical {
            display: none !important;
          }
        }
      }
    }

    .vjs-playing {
      .mob-pause-btn {
        display: block;
      }

      .mob-play-btn {
        display: none;
      }
    }

    .vjs-paused {
      .mob-pause-btn {
        display: none;
      }

      .mob-play-btn {
        display: block;
      }
    }

    .vjs-ended {
      .mob-pause-btn {
        display: none;
      }

      .mob-play-btn {
        display: none;
      }
    }

    .vjs-play-control {
      display: none !important;
    }

    //every play
    &.mobile-played {
      .styled-wrapper-inner-container {
        display: flex;
        justify-content: center;
      }

      .styled-wrapper-inner-container {
        flex-direction: unset;
      }
    }

    .has-played {
      position: absolute;
      top: 100px;
      margin-top: unset;

      .mobile-make-clip-btn {
        display: block;
      }

      .video-js {
        //width: calc(100vw - 110px);
        width: calc(16 / 9 * 80vh);
        height: 80vh;
      }

      .black-background {
        display: block;
        height: 100%;

        img {
          position: fixed;
          top: 20px;
          right: 20px;
        }
      }

      .video-cameras {
        display: flex !important;
      }

      .mobile-make-clip-btn {
        display: block;
      }

      .vjs-control-bar {
        visibility: visible;
      }

      .add-marker {
        display: flex;
      }
    }

    //user inactive
    .user-inactive {
      .vjs-has-started {
        .vjs-control-bar {
          //display: none;
        }
      }
      .mob-pause-btn {
        display: none;
      }

      .video-cameras {
        display: none !important;
      }

      .mobile-make-clip-btn {
        display: none;
      }

      &.has-played {
        .mob-play-btn {
          display: none;
        }

        .video-js {
          .vjs-control-bar {
            visibility: visible !important;
            opacity: 1 !important;
            display: flex;
            justify-content: flex-end;
            background: none !important;
            height: 40px;
            padding-top: 10px;
            align-items: center;

            .vjs-seek-button {
              display: none;
            }

            .vjs-volume-panel {
              display: none;
            }

            .vjs-menu-settings {
              display: none !important;
            }
          }
        }

        .add-marker {
          left: 10px;
          bottom: 29px;
          width: 30px !important;
          height: 39px !important;
          display: none;
          justify-content: center;
          padding-top: 12px !important;
          z-index: 2 !important;
          background-color: rgba(0, 0, 0, 0.25);

          .add-marker-text {
            display: none;
          }

          .add-marker-img {
            img {
              width: 12px;
              height: 18px;
              margin-right: unset;
            }
          }
        }
      }
    }

    //user active
    .user-active.has-played {
      .vjs-has-started {
        .vjs-control-bar {
          visibility: visible !important;
          opacity: 1 !important;
          display: flex;
          justify-content: flex-end;
          background: transparent !important;
          height: 40px;
          padding-top: 10px;
          align-items: center;
        }
      }
    }

    //make a clip
    .mobile-make-clip-btn {
      position: absolute;
      z-index: 3;
      padding: unset;
      margin: unset;
      height: 38px;
      width: 38px;
      bottom: 0;
      right: -48px;
      left: unset;
      top: unset;
      img {
        width: 15px;
        height: 15px;
      }
    }

    .hidden-bar {
      .black-background {
        img {
          display: none;
        }
      }

      .confirm-modal-container {
        background: rgba(0, 0, 0, 0.85);
      }

      .make-clip-input-section {
        bottom: -77px;
      }

      .make-clip-input-wrapper {
        justify-content: flex-start;
        padding: 0;
        height: 40px;
      }

      .save-clip-button {
        margin: 0 15px;
      }

      .delete-cancel-buttons-container {
        display: contents;
      }

      .style-progress-bar-container {
        bottom: 0;
        div {
          font-size: 12px;
        }
      }

      .rc-slider {
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
        padding: unset;
        height: 54px;

        .rc-slider-handle {
          top: 5px;
          background-size: unset;
          div {
            font-size: 9px;
            background: unset;
            height: 11px;
            padding: unset;
            margin-top: -20px;
            margin-left: 5px;
          }
        }

        .rc-slider-handle-2 {
          div {
            margin-left: -5px;
          }
        }
      }

      .preview-clip-text {
        display: none;
      }
    }

    .edit-clip-container {
      width: 300px;
      height: 194px;
    }

    .confetti-modal-container {
      width: 400px;
      height: 300px;

      .confetti-modal-title {
        font-size: 12px;
        margin-top: 22px;
        margin-bottom: 34px;
      }

      .gif-container {
        margin-top: 15px;
        max-width: 238px;
        img {
          object-fit: contain;
        }
      }
    }

    .success-bar-container {
      width: 400px;
      height: 300px;

      .close-icon-container {
        top: 24px;
        right: 21px;
      }

      .success-bar-title {
        font-size: 12px;
        margin-bottom: 48px;
        margin-top: 72px;
      }

      .download-copy-block {
        width: 210px;

        .copy-block {
          padding: 15px 4px 14px 8px;
          &:active,
          &:hover {
            background-color: #dbedff;
          }

          .link-copied {
            justify-content: unset;
            //color: #000;
            //img {
            //  filter: unset;
            //}
          }

          div {
            margin-top: unset;
            justify-content: space-between;
            width: 100%;
            opacity: 1;

            img {
              width: 16px;
              height: 16px;
              margin-right: unset;
            }

            span {
              font-size: 14px;
              margin-left: 60px;
            }
          }
        }

        .download-block {
          padding: 16px 0 14px 8px;
          &:active,
          &:hover {
            background-color: #dbedff;
          }

          .download-section {
            margin-top: unset;
            opacity: 1;

            img {
              width: 14px;
              height: 16px;
              margin-right: unset;
            }
            span {
              font-size: 14px;
              margin-left: 63px;
            }
          }
        }
      }
    }

    .vjs-control-bar {
      .vjs-progress-control {
        .vjs-tip {
          display: none;
        }
        .vjs-progress-holder {
          .vjs-marker {
            top: 14px;
            width: 12px !important;
            height: 18px !important;

            :hover {
              transform: unset;
            }
          }
          .active-marker {
            top: -23px;
            margin-top: 0 !important;
            width: 12px !important;
            height: 18px !important;
          }
        }
      }
    }

    .vjs-user-active {
      .add-marker {
        left: 10px;
        bottom: 29px;
        width: 30px !important;
        height: 39px !important;
        display: none;
        justify-content: center;
        padding-top: 12px !important;
        z-index: 2 !important;
        background-color: rgba(0, 0, 0, 0.25);

        .add-marker-text {
          display: none;
        }

        .add-marker-img {
          img {
            width: 12px;
            height: 18px;
            margin-right: unset;
          }
        }
      }
    }

    .message-container {
      max-width: 320px;
      width: 100%;
      padding: 13px 72px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mobile-marker-message {
      .message-container {
        background: rgba(0, 0, 0, 0.8);
        margin: 0 auto;
        width: fit-content;
        top: 0;
        position: fixed;
        padding: 13px 25px;
        border-radius: 3px;
      }
    }

    //add marker
    .add-marker-active {
      .add-marker {
        display: none !important;
      }

      .cameras-container {
        display: none;
      }

      .vjs-progress-control {
        pointer-events: none;
      }
      .vjs-marker {
        display: none;
      }
      .active-marker {
        display: block;
        margin-top: unset !important;
        top: -23px;
      }

      .mobile-make-clip-btn,
      .mob-play-btn,
      .mob-pause-btn {
        display: none;
      }

      .marker-desc-container {
        bottom: -66px;
        height: 40px;
        .marker-desc-content {
          display: flex !important;
          padding: unset;
          width: inherit;
          grid-gap: 15px;

          .marker-input {
            width: 100%;
          }

          .add-marker-button {
            min-width: 129px;
            display: flex;
            order: 1;
            margin: 0 0 0 5px;
            padding: 12px 25px 11px 25px;
          }

          .marker-cancel {
            display: none;
            order: 0;
          }
        }
      }

      .video-js {
        .vjs-control-bar {
          visibility: visible !important;
          opacity: 1 !important;
          display: flex;
          justify-content: flex-end;
          background: none !important;
          height: 40px;
          padding-top: 10px;
          align-items: center;

          .vjs-seek-button {
            display: none;
          }

          .vjs-volume-panel {
            display: none;
          }

          .vjs-menu-settings {
            display: none !important;
          }
        }
      }
    }

    &.add-marker-active-mobile {
      .main-video-info-wrapper,
      .mobile-exit-marker-btn {
        display: none !important;
      }

      .black-background {
        img {
          display: block !important;
        }
      }
    }

    &.edit-marker-mode-mobile {
      .add-marker {
        display: none !important;
      }
    }

    &.mobile-view-marker-data {
      .is-mobile-markers {
        z-index: 3;
        display: block;

        .is-highlight-marker {
          display: none;
        }
      }

      .styled-mobile-container {
        position: relative;
        padding: 0;

        .title-container,
        .labels-wrapper {
          display: none;
        }

        .main-video-info-wrapper {
          width: calc(16 / 9 * 80vh);
          margin-top: calc(80vh - 40px);
          overflow: scroll;
          max-height: 38vh;
          .is-mobile-markers {
            .styled-marker-container {
              padding: 4px 0;
              margin-left: unset;
              display: flex;
              gap: 5px;
              width: 100%;
              align-items: center;

              .styled-marker-text {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-left: 15px;
                color: white;
                width: 100%;
              }
            }
          }
        }
      }

      .video-js {
        .vjs-control-bar {
          .vjs-seek-button {
            display: none;
          }

          .vjs-volume-panel {
            display: none;
          }

          .vjs-menu-settings {
            display: none !important;
          }
        }
      }

      .mobile-make-clip-btn {
        display: none;
      }

      .cameras-container {
        display: none;
      }

      .black-background {
        img {
          display: none;
        }
      }

      .mobile-exit-marker-btn {
        display: block;
        position: fixed;
        top: 20px;
        right: 20px;
      }

      .opacity_55 {
        opacity: 0.55;
      }
    }

    //edit marker
    .edit-marker-mode {
      .vjs-marker:not(.mobile-active) {
        display: none !important;
      }
    }
  }

  //@media screen and (min-width: 360px) and (orientation: portrait) {
  //}
}
